@font-face {
  font-family: MontserratBold;
  src: url(/src/assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: MontserratRegular;
  src: url(/src/assets/fonts/Montserrat-Regular.ttf);
}

:root {
  --dark-bgcolor: #002F67;
  --lightBLue-color: #6EC0FF;
  --lightBLue-disavledcolor: #749CBB;
  --dark-fontcolor: #353C6F;
  --success-color: #02BD54;
  --failur-color: #F23838;
  --failur-textcolor: #ee6262;
  --white-color: #fff;
  --black-color: #000;
  --gray-color: #CCCDD9;
}

.mainCont {
  min-height: 100vh;
  height: 100vh;
}

.imgContainer {
  background-color: var(--dark-bgcolor);
  height: 100vh;
}

.primary-bg {
  background-color: var(--dark-bgcolor);
}

.playerMainContainer {
  margin: 0 20px;
}

.tabTitle {
  font: normal normal bold 36px/56px MontserratBold;
  letter-spacing: 2.05px;
  color: var(--lightBLue-color);
  opacity: 0.34;
}

.answerBtnsRow {
  margin-top: 50px;
}

.answerClicked {
  background-color: var(--success-color);
}

.waitText {
  font: normal normal normal 41px/50px MontserratRegular;
  color: var(--white-color);
}

.buzzer {
  margin-top: 50px;
  padding: 100px 20px;
  font: normal normal bold 93px/49px MontserratBold;
  letter-spacing: 4.65px;
  border-radius: 9px;
  position: relative;
  cursor: pointer;
}

.buzzerContainer {
  background-color: var(--white-color);
  color: var(--dark-fontcolor);
}

.disabledBuzzerContainer {
  background-color: var(--gray-color);
  color: #353c6f67;
}

.buzzer::after {
  width: 95%;
}

.tabAnswerLetterContainerDisabled {
  background-color: var(--gray-color);
  color: #353c6f67;
  padding: 100px 0;
  border-radius: 9px;
  cursor: pointer;
}

.tabAnswerLetterContainer {
  background-color: var(--white-color);
  padding: 100px 0;
  border-radius: 9px;
  cursor: pointer;
}

.tabAnswerLetterContainer:hover {
  opacity: 0.7;
}

.tabAnswerLetterContainerDisabled::after,
.tabAnswerLetterContainer::after {
  width: 80%;
}

.bottomShadow:after {
  content: '';
  position: absolute;
  height: 10px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 9px 9px;
}

.bottomLightBlueShadow::after {
  background-color: var(--lightBLue-color);
}

.tabAnswerLetter {
  font: normal normal bold 120px/40px MontserratBold;
  letter-spacing: 7.1px;
  color: var(--dark-fontcolor);
  user-select: none;
}

.loginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  background-color: #f8f9fd;
}

.loginPage input {
  font-family: MontserratRegular;
}

.loginPage label {
  font-family: MontserratBold;
}

input[type="text"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: var(--lightBLue-color);
  border: none;
  box-shadow: 1px 1px 0 1px var(--lightBLue-disavledcolor);
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
  font-family: MontserratBold;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

.errorContainer {
  width: fit-content;
}

.refreshSvg {
  fill: #597a94;
  width: 50px;
  height: 50px;
  transform: rotate(-100deg);
}

.cardClicked {
  transition: all .5s ease-in-out;
  animation: clickedEffect .5s ease-in-out;
  -webkit-animation: clickedEffect .5s ease-in-out;
}

@keyframes clickedEffect {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.125);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes clickedEffect {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.125);
  }

  100% {
    transform: scale(1);
  }
}